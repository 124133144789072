<template>
    <div>
        As the food industry association, FMI works with and on behalf of the
        entire industry to advance a safer, healthier and more efficient
        consumer food supply chain. FMI brings together a wide range of members
        across the value chain — from retailers who sell to consumers, to
        producers who supply the food, as well as the wide variety of companies
        providing critical services — to amplify the collective work of the
        industry. Dive into some of the FMI member benefits below.

        <hr />

        <h2>Coronavirus Resources</h2>
        <div class="resource-list">
            <a
                :href="resource.url"
                :key="resource.image"
                v-for="resource in covidResources"
                target="_blank"
                rel="noopener noreferrer"
            >
                <span class="sr-only"
                    >{{ resource.title }} (opens in a new window)</span
                >
                <img
                    :src="`${imgPath}${resource.image}`"
                    :alt="`Cover for ${resource.title}`"
                    width="224"
                    height="280"
                />
            </a>
        </div>

        <hr />

        <h2>Events</h2>
        <div class="resource-list">
            <a
                :href="resource.url"
                :key="resource.image"
                v-for="resource in events"
                target="_blank"
                rel="noopener noreferrer"
            >
                <span class="sr-only"
                    >{{ resource.title }} (opens in a new window)</span
                >
                <img
                    :src="`${imgPath}${resource.image}`"
                    :alt="`Logo for ${resource.title}`"
                    width="224"
                    height="186"
                />
            </a>
        </div>

        <hr />

        <h2>FMI Foundation</h2>
        <div class="resource-list">
            <a
                :href="resource.url"
                :key="resource.image"
                v-for="resource in foundation"
                target="_blank"
                rel="noopener noreferrer"
            >
                <span class="sr-only"
                    >{{ resource.title }} (opens in a new window)</span
                >
                <img
                    :src="`${imgPath}${resource.image}`"
                    :alt="`Cover for ${resource.title}`"
                    width="224"
                    height="280"
                />
            </a>
        </div>

        <hr />

        <h2>FMI Research</h2>
        <div class="resource-list">
            <a
                :href="resource.url"
                :key="resource.image"
                v-for="resource in research"
                target="_blank"
                rel="noopener noreferrer"
            >
                <span class="sr-only"
                    >{{ resource.title }} (opens in a new window)</span
                >
                <img
                    :src="`${imgPath}${resource.image}`"
                    :alt="`Cover for ${resource.title}`"
                    width="224"
                    height="280"
                />
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "resourcesAbc",
    data() {
        return {
            imgPath: "https://fmi-abc.s3.amazonaws.com/resources/",
            covidResources: [
                {
                    image: "DeEscalation-Cover.png",
                    url:
                        "https://www.fmi.org/forms/RedirectForm/?dest=https%3A%2F%2Fwww.fmi.org%2Fforms%2Fstore%2FProductFormPublic%2Ffmi-crisis-management-guide-de-escalation&utm_campaign=CrisisContinuity&utm_source=abc&utm_medium=platform",
                    title: "FMI Crisis Management Guide: De-Escalation"
                },
                {
                    image: "Coronavirus-Cover-1.png",
                    url:
                        "https://www.fmi.org/forms/RedirectForm/?dest=https%3A%2F%2Fwww.fmi.org%2Fdocs%2Fdefault-source%2Ffood-safety%2Fpandemic-planning-final_verison3-12-20-6.pdf&utm_campaign=COVID19&utm_source=abc&utm_medium=platform",
                    title:
                        "Coronavirus and Pandemic Preparedness for the Food Industry"
                },
                {
                    image: "Coronavirus-Cover-Part+1.png",
                    url:
                        "https://www.fmi.org/forms/RedirectForm/?dest=https%3A%2F%2Fwww.fmi.org%2Fdocs%2Fdefault-source%2Fcoronavirus%2Ffood-industry-best-practices---current-situationa7cc0fe7592a42ddbb1e1db2abb9c3da.pdf&utm_campaign=COVID19&utm_source=abc&utm_medium=platform",
                    title:
                        "Guidance for the Food Industry: Coronavirus Outbreak Pt. 1"
                },
                {
                    image: "Coronavirus-Cover2.png",
                    url:
                        "https://www.fmi.org/forms/RedirectForm/?dest=https%3A%2F%2Fwww.fmi.org%2Fdocs%2Fdefault-source%2Fcoronavirus%2Ffood-industry-best-practices---short-term1279f0073cb6492d87b8799f97ca9ccc.pdf&utm_campaign=COVID19&utm_source=abc&utm_medium=platform",
                    title:
                        "Guidance for the Food Industry: Coronavirus Outbreak Pt. 2"
                },
                {
                    image: "Coronavirus-Cover3.png",
                    url:
                        "https://www.fmi.org/forms/RedirectForm/?dest=https%3A%2F%2Fwww.fmi.org%2Fdocs%2Fdefault-source%2Fcoronavirus%2Ffood-industry-suggested-business-practices---3.pdf&utm_campaign=COVID19&utm_source=abc&utm_medium=platform",
                    title:
                        "Guidance for the Food Industry: Coronavirus Outbreak Pt. 3"
                },
                {
                    image: "Coronavirus-Cover4.png",
                    url:
                        "https://www.fmi.org/forms/RedirectForm/?dest=https%3A%2F%2Fwww.fmi.org%2Fdocs%2Fdefault-source%2Fcoronavirus%2Fiv-food-sector-continuity-in-a-reopening-economy.pdf&utm_campaign=COVID19&utm_source=abc&utm_medium=platform",
                    title:
                        "Guidance for the Food Industry: Coronavirus Outbreak Pt. 4"
                },
                {
                    image: "Coronavirus-Resources.png",
                    url:
                        "https://www.fmi.org/forms/RedirectForm/?dest=https%3A%2F%2Fwww.fmi.org%2Ffood-safety%2Fcoronavirus&utm_campaign=COVID19&utm_source=abc&utm_medium=platform",
                    title: "Further FMI Coronavirus Resources"
                }
            ],
            events: [
                {
                    image: "Virtual-Events-224x186-logo-FLeX.png",
                    url:
                        "https://www.fmi.org/forms/RedirectForm/?dest=https%3A%2F%2Fwww.fmi.org%2Ffuture-leaders&utm_campaign=futureleaders20&utm_source=abc&utm_medium=platform",
                    title: "Future Leaders"
                },
                {
                    image: "Virtual-Events-224x186-logo-PBS.png",
                    url:
                        "https://www.fmi.org/forms/RedirectForm/?dest=https%3A%2F%2Fwww.fmi.org%2Fprivate-brands-dc-summit&utm_campaign=PBS20&utm_source=abc&utm_medium=platform",
                    title: "Private Brands"
                },
                {
                    image: "Virtual-Events-224x186-logo-SQF.png",
                    url:
                        "https://www.sqfi.com/forms/RedirectForm/?dest=https%3A%2F%2Fwww.sqfi.com%2Fsqf-conference%2F&utm_campaign=SQFGlobal20&utm_source=generic&utm_medium=generic&utm_content=SQFGlobal20",
                    title: "SQF Global"
                },
                {
                    image: "Virtual-Events-224x186-logo-Midwinter.png",
                    url:
                        "https://www.fmi.org/forms/RedirectForm/?dest=https%3A%2F%2Fwww.fmi.org%2Fmidwinter-conference&utm_campaign=midwinter21&utm_source=abc&utm_medium=platform",
                    title: "Midwinter"
                },
                {
                    image: "Virtual-Events-224x186-logo-More.png",
                    url:
                        "https://www.fmi.org/forms/RedirectForm/?dest=https%3A%2F%2Fwww.fmi.org%2Fevents-education%2Fconferences&utm_campaign=fmi_events&utm_source=abc&utm_medium=platform",
                    title: "More FMI Events"
                }
            ],
            foundation: [
                {
                    image: "Power-of-Health-_-Well_being-2020.png",
                    url:
                        "https://www.fmi.org/forms/RedirectForm/?dest=https%3A%2F%2Fwww.fmi.org%2Fforms%2Fstore%2FProductFormPublic%2Fpower-of-health-and-well-being-in-food-retail-2020&utm_campaign=healthandwellbeing2020&utm_source=abc&utm_medium=platform",
                    title: "The Power of Health and Well-Being in Food Retail"
                },
                {
                    image: "Cooking-Trends.png",
                    url:
                        "https://www.fmi.org/forms/RedirectForm/?dest=https%3A%2F%2Fwww.fmi.org%2Fforms%2Fstore%2FProductFormPublic%2Fhome-cooking-in-america-2020-a-special-report-based-on-u-s-grocery-shopper-trends&utm_campaign=grocerytrends&utm_source=abc&utm_medium=platform",
                    title: "Home Cooking in America 2020"
                },
                {
                    image: "FMM-Manu-Toolkit.png",
                    url:
                        "https://www.fmi.org/forms/RedirectForm/?dest=https%3A%2F%2Fwww.fmi.org%2Ffamily-meals%2Ftoolkits%2Fmanufacturer-toolkit&utm_campaign=NFMM&utm_source=abc&utm_medium=platform",
                    title: "National Family Meals Month: Manufacturer Toolkit"
                },
                {
                    image: "FMM-Retail-Toolkit.png",
                    url:
                        "https://www.fmi.org/forms/RedirectForm/?dest=https%3A%2F%2Fwww.fmi.org%2Ffamily-meals%2Ftoolkits%2Fretailer-toolkit&utm_campaign=NFMM&utm_source=abc&utm_medium=platform",
                    title: "National Family Meals Month: Food Retailer Toolkit"
                },
                {
                    image: "More-from-the-FMI-Foundation.png",
                    url:
                        "https://www.fmi.org/forms/RedirectForm/?dest=https%3A%2F%2Fwww.fmi.org%2Ffoundation&utm_campaign=Foundation&utm_source=abc&utm_medium=platform",
                    title: "More from the FMI Foundation"
                }
            ],
            research: [
                {
                    image: "Trends-2020-Cover.png",
                    url:
                        "https://www.fmi.org/forms/RedirectForm/?dest=https%3A%2F%2Fwww.fmi.org%2Fforms%2Fstore%2FProductFormPublic%2Fu-s-grocery-shopper-trends-2020&utm_campaign=grocerytrends&utm_source=abc&utm_medium=platform",
                    title: "U.S. Grocery Shopper Trends 2020"
                },
                {
                    image: "COVID-19-Technology-Checkup-Report.png",
                    url:
                        "https://www.fmi.org/forms/RedirectForm/?dest=https%3A%2F%2Fwww.fmi.org%2Fforms%2Fstore%2FProductFormPublic%2Fits-time-for-a-covid-19-technology-checkup-a-prescription-for-food-retail&utm_campaign=fmitech&utm_source=abc&utm_medium=platform",
                    title: "It’s Time for a COVID-19 Technology Checkup"
                },
                {
                    image: "Transparency-Trends-Reort-Cover.png",
                    url:
                        "https://www.fmi.org/forms/RedirectForm/?dest=https%3A%2F%2Fwww.fmi.org%2Fforms%2Fstore%2FProductFormPublic%2Ftransparency-trends-omnichannel-grocery-shopping-from-the-consumer-perspective&utm_campaign=transparencytrends20&utm_source=abc&utm_medium=platform",
                    title:
                        "Transparency Trends: Omnichannel Grocery Shopping from the Consumer Perspective"
                },
                {
                    image: "Power-of-Seafood-2020-Cover.png",
                    url:
                        "https://www.fmi.org/forms/RedirectForm/?dest=https%3A%2F%2Fwww.fmi.org%2Fforms%2Fstore%2FProductFormPublic%2Fpower-of-seafood-2020&utm_campaign=powerofseafood2020&utm_source=abc&utm_medium=platform",
                    title: "The Power of Seafood 2020"
                },
                {
                    image: "The-Power-of-Meat-2020-Cvr.png",
                    url:
                        "https://www.fmi.org/forms/RedirectForm/?dest=https%3A%2F%2Fwww.fmi.org%2Fforms%2Fstore%2FProductFormPublic%2Fpower-of-meat-2020&utm_campaign=Powerofmeat2020&utm_source=abc&utm_medium=platform",
                    title: "The Power of Meat 2020"
                },
                {
                    image: "Power-of-Produce.png",
                    url:
                        "https://www.fmi.org/forms/RedirectForm/?dest=https%3A%2F%2Fwww.fmi.org%2Fforms%2Fstore%2FProductFormPublic%2Fpower-of-produce-2020&utm_campaign=Powerofproduce2020&utm_source=abc&utm_medium=platform",
                    title: "The Power of Produce 2020"
                },
                {
                    image: "Furthur-FMI-Research.png",
                    url:
                        "https://www.fmi.org/forms/store/CommercePlusFormPublic/search?action=Feature",
                    title: "Further FMI Research"
                }
            ]
        };
    }
};
</script>

<style lang="scss" scoped>
@import "../../styles/setup/colors";
@import "../../styles/views/resources";
</style>
